@import '../../../general/scss/settings/variables.scss';

label {
    &:hover {
        cursor: pointer;
    }

    &.col-form-label {
        padding-bottom: 0;
        /*padding-top: 0;*/
    }
}

.form-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem !important;
}

.form-control {
    padding-bottom: 0.45rem;
    padding-top: 0.45rem;

    &.number {
        width: 25%;
    }
}

/*.datepicker {
	font-size: 0.875em;
	width: auto !important;
}*/
.datepicker-panel > ul > li {
    line-height: inherit;
}

.datepicker,
.monthpicker {
    background-color: transparent !important;

    &:hover {
        cursor: pointer;
    }
}

.datepicker-trigger {
    margin-left: -56px;

    button {
        background-color: $unipol-off-white;
    }
}

@include media-breakpoint-up(md) {
    .form-control {
        padding-bottom: 0.797rem;
        padding-top: 0.797rem;

        &.short {
            padding-bottom: 0.375rem;
            padding-top: 0.375rem;
        }
    }
}

.filter-bar .form-control {
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
}

.input-group {
    & > input[type='text'],
    & > textarea,
    & > select{
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        min-width: 0;
        margin-bottom: 0;
    }
    .input-group-prepend ~ select:not([size]):not([multiple]) {
        margin-left: -2px;
    }

    .input-group-append ~ select:not([size]):not([multiple]) {
        margin-right: -2px;
    }
}

.input-group-text {
    border: 1px solid $unipol-light-blue;
    z-index: 1;
}

.field-validation-error,
.EditingFormErrorLabel,
.ErrorLabel {
    color: $red;

    @extend .col-12 !optional;
    padding-left: 0;
    padding-right: 0;
}

/*input[type=checkbox], input[type=radio] {
	-webkit-appearance: checkbox !important;
}*/
select:not([size]):not([multiple]) {
    cursor: pointer;
    /*height: auto !important;*/
    height: calc(3rem + 2px) !important;
}

input[type='text'],
input[type='password'],
textarea,
.TextBoxField,
.TextboxItemShort,
select,
.form-control {
    @extend .form-control;
    border: 1px solid $unipol-light-blue !important;
    height: auto;
    width: 100%;
    padding: .797rem;
    font-size: 1rem;
    color: #495057;
    background-color: white;

}
input:disabled[type='text'] {
    background-color: #e9ecef;
    opacity: 1;
}
.TextBoxField:focus,
.TextAreaField:focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.TextareaItem {
    min-height: 10rem;
}

.inline-checkbox {
    .EditingFormControlNestedControl {
        display: inline-block;

        .custom-control-input {
            margin-left: 5px;
        }
    }

    input[type='checkbox'],
    input[type='radio'] {
        margin-right: 10px;
    }
}

/*label.for-checkbox {
	width: auto !important;
}*/
.EditingFormControlNestedControl {
    select:not([size]):not([multiple]) {
        width: 100%;
    }
}

.input-imp-long {
    display: none !important;
}

.ItemLabel {
    width: 100%;
}

@include media-breakpoint-up(md) {
    .ItemLabel {
        width: 35%;
    }
}

.SubmitButton {
    margin-right: 0.5rem;
}

.PasswStrenghtIndicator {
    max-width: 200px;
}

.search-form {
    position: relative;
    display: inline-block;

    .icon-search {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        color: $unipol-light-blue;
    }

    input {
        padding-right: 35px;
    }
}

.form-row {
    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.form-col1 {
    width: 100%;
    padding: 0.125rem;
    @include media-breakpoint-up(md) {
        width: 30%;
        padding: 0.25rem;
    }
}

.form-col2 {
    width: 100%;
    padding: 0.125rem;
    @include media-breakpoint-up(md) {
        width: 70%;
        padding: 0.25rem;
    }
}

.umbraco-forms-field {
    padding: 1rem 0;
}
.umbraco-forms-field.hidden{
    padding:  0;
}
.umbraco-forms-label{
    margin: 0;
}
.umbraco-forms-field.checkbox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: start;
    align-items: center;
    gap: .6rem;
    .umbraco-forms-field-wrapper,
    .umbraco-forms-label {
        margin: 0;
    }
}

.input-money{
    position: relative;
    flex: 1 1 auto;
    width: 1% !important;
    min-width: 0;
    margin-bottom: 0;
}

.ck.ck-editor__main>.ck-editor__editable {
    position: relative;
}
